Blockly.Blocks['env3d_loop_between'] = {
    init: function() {
        this.jsonInit({
            "message0": "from %1 to %2 seconds",
            "message1": "do: %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "START"
                },
                {
                    "type": "input_value",
                    "name": "END"
                }
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Execute some code between 2 times during an event loop",
            "helpUrl": "https://www.c3d.io/help"
        });
    },    
};

Blockly.JavaScript['env3d_loop_between'] = function(block) {
    var start = Blockly.JavaScript.valueToCode(block, 'START',
                                               Blockly.JavaScript.ORDER_NONE) || 0;
    var end = Blockly.JavaScript.valueToCode(block, 'END',
                                             Blockly.JavaScript.ORDER_NONE) || 0;
    // convert time to frame count
    maxStart = 'Math.max(0, Math.trunc('+start+'*30)-1)';
    maxEnd = 'Math.max(0, Math.trunc('+end+'*30)-1)';

    start = 'Math.trunc('+start+'*30)';
    end = 'Math.trunc('+end+'*30)';

    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');

    var finalCode =  [`if (this.maxFrame < ${maxEnd}) this.maxFrame = ${maxEnd};`,
                      `if (this.frame >= ${start} && this.frame < ${end}) {`,
                      `  if (typeof this['${block.id}'] === 'undefined') {`,
                      `    this['${block.id}'] = true;`,
                      `    (async() => {`,
                      code.split('\n').map(l=>`    ${l}`).join('\n'),
                      `    })().then(()=>delete this['${block.id}']);`,
                      `  }`,
                      '}\n'].join('\n');

    // check if previous statement is also a timing block
    if (block.getParent() != block.getSurroundParent()) {
        var prevStart = Blockly.JavaScript.valueToCode(block.getParent(), 'START',
                                                       Blockly.JavaScript.ORDER_NONE) || 0;
        var prevEnd = Blockly.JavaScript.valueToCode(block.getParent(), 'END',
                                                     Blockly.JavaScript.ORDER_NONE) || 0;

        finalCode = '// prev start at '+prevStart+' and end at '+prevEnd+' \n' + finalCode;
    }
    
    return finalCode;
};


Blockly.Blocks['env3d_loop_at'] = {
    init: function() {
        this.jsonInit({
            "message0": "at %1 seconds",
            "message1": "do: %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "AT"
                }
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Execute some code between 2 times during an event loop",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop_at'] = function(block) {
    var at = Blockly.JavaScript.valueToCode(block, 'AT',
        Blockly.JavaScript.ORDER_NONE) || 0;
    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    var frame = 'Math.max(0, Math.trunc('+at+'*30)-1)';

    var finalCode =  [`if (this.maxFrame < ${frame}) this.maxFrame = ${frame};`,
                      `if (this.frame == ${frame}) {`,
                      `  (async() => {`,
                      code.split('\n').map(l=>`    ${l}`).join('\n'),
                      `  })()`,
                      '}\n'].join('\n');

    return finalCode;

};

Blockly.Blocks['env3d_loop_reset'] = {
    init: function() {
        this.jsonInit({
            "message0": "reset loop",
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Reset the seconds counter so actions would repeat",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop_reset'] = function(block) {
    return 'this.frame = 0;\n';
};

Blockly.Blocks['env3d_loop_reset_at'] = {
    init: function() {
        this.jsonInit({
            "message0": "reset at %1 seconds",
            "args0": [
                {
                    "type": "input_value",
                    "name": "AT"
                }
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Reset the seconds counter so actions would repeat",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop_reset_at'] = function(block) {
    var at = Blockly.JavaScript.valueToCode(block, 'AT',
        Blockly.JavaScript.ORDER_NONE) || 0;
    return 'if (this.frame == '+Math.trunc(at*30)+') this.frame = 0;\n';
};


// an experiment to see if we can implicitly get the startFrame from previous block
Blockly.Blocks['env3d_loop_for_seconds'] = {
    init: function() {
        this.jsonInit({
            "message0": "for the next %1 seconds",
            "message1": "do %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "DURATION"
                },
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }         
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Do something for x number of seconds",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop_for_seconds'] = function(block) {
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                                  Blockly.JavaScript.ORDER_NONE) || 0;

    // check if previous statement is also a timing block
    if (block.getParent() != block.getSurroundParent()) {
        var prevStart = block.getParent().env3dTimerStart;
        var prevEnd = block.getParent().env3dTimerEnd;

        block.env3dTimerStart = prevEnd;
        block.env3dTimerEnd = '('+prevEnd + '+' + duration+')';
    } else {
        // parent is at the top, start timer at 0
        block.env3dTimerStart = 0;
        block.env3dTimerEnd = duration;
    }

    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    
    let start = 'Math.max(0, Math.trunc(('+block.env3dTimerStart+')*30)-1)';
    let end = 'Math.max(0, Math.trunc(('+block.env3dTimerEnd+')*30)-1)';
    
    let finalCode =  '// we start at '+start+' and end at '+end+'\n'
                   + 'if (this.maxFrame < '+end+') this.maxFrame = '+end+';\n'
                   + 'if (this.frame >= '+start+' && this.frame < '+end+') {\n'
                   + code +'\n'
                   + '}\n';
    
    return finalCode;    
};

Blockly.Blocks['env3d_wait_for_seconds'] = {
    init: function() {
        this.jsonInit({
            "message0": "wait %1 seconds",
            "args0": [
                {
                    "type": "input_value",
                    "name": "DURATION"
                },
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "wait for a number of seconds",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_wait_for_seconds'] = function(block) {
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                                  Blockly.JavaScript.ORDER_NONE) || 0;

    // check if previous statement is also a timing block
    if (block.getParent() != block.getSurroundParent()) {
        var prevStart = block.getParent().env3dTimerStart;
        var prevEnd = block.getParent().env3dTimerEnd;

        block.env3dTimerStart = prevEnd;
        block.env3dTimerEnd = '('+prevEnd + '+' + duration+')';
    } else {
        // parent is at the top, start timer at 0
        block.env3dTimerStart = 0;
        block.env3dTimerEnd = duration;
    }
    
    let start = 'Math.max(0, Math.trunc('+block.env3dTimerStart+'*30)-1)';
    let end = 'Math.max(0, Math.trunc('+block.env3dTimerEnd+'*30)-1)';
    
    let finalCode =  '// we start at '+start+' and end at '+end+'\n'
                   + 'if (this.maxFrame < '+end+') this.maxFrame = '+end+';\n'
    
    return finalCode;    
};

// Attach this function to all onchange handlers to restrict timing blocks to event loops
var onchange = function() {
    if (!this.workspace.isDragging || this.workspace.isDragging()) {
        return;  // Don't change state at the start of a drag.
    }
    
    var legal = false;
    // Is the block nested in a procedure?
    var block = this;        
    do {
        let parent = block.getSurroundParent();
        
        // disallow timing block within timing block
        if (block.type != 'env3d_loop_reset' && parent && TIMING_TYPES.indexOf(parent.type) != -1) {
            break;
        }
        
        if (this.LEGAL_TYPES.indexOf(block.type) != -1) {
            legal = true;
            break;
        }
        
        // traverse upwards
        block = parent
        
    } while (block);
    
    if (legal) {
        if (!this.isInFlyout) {
            this.setDisabled(false);
            this.setWarningText(null);
        }
    } else {
        if (!this.isInFlyout && !this.getInheritedDisabled()) {
            this.setDisabled(true);
            this.setWarningText('Animation timing must be put inside animation loop or functions');
        }
        
    }
}
            
// timing blocks are only allowed inside loops and events
var LEGAL_TYPES = Blockly.Env3D.EVENT_BLOCK_TYPES.concat('procedures_defnoreturn');
var TIMING_TYPES = ['env3d_loop_between', 'env3d_loop_at', 'env3d_loop_reset', 'env3d_loop_reset_at',
                    'env3d_loop_for_seconds', 'env3d_wait_for_seconds'];
TIMING_TYPES.forEach(function(type){
    Blockly.Blocks[type].onchange = onchange;
    Blockly.Blocks[type].LEGAL_TYPES = LEGAL_TYPES;    
});
