require("../components/config.js");

// Utilitiy block allowing a number field with an angle selector
Blockly.Blocks["math_angle"] = {
  init: function() {
    this.jsonInit({
      message0: "%1",
      args0: [
        {
          type: "field_angle",
          name: "NUM",
          value: 0
        }
      ],
      output: null,
      colour: 160,
      tooltip: "Returns an angle",
      helpUrl: "https://www.c3d.io/help"
    });
  }
};

Blockly.JavaScript["math_angle"] = function(block) {
  var angle = block.getFieldValue("NUM") || 0;
  return [angle, Blockly.JavaScript.ORDER_MEMBER];
};



// A tinkercad object with url field for model
Blockly.Blocks["env3d_object_tinker"] = {
  init: function() {
    this.jsonInit({
      message0: "TinkerCad / SketchUp Object",
      message1: "position: x= %1 y= %2 z= %3",
      message2: "rotation: x= %1 y= %2 z= %3",
      message3: "scale * %1",
      // message4: "model %1",
      args1: [
        {
          type: "field_number",
          name: "X",
          value: 0
        },
        {
          type: "field_number",
          name: "Y",
          value: 0
        },
        {
          type: "field_number",
          name: "Z",
          value: 0
        }
      ],
      args2: [
        {
          type: "field_angle",
          name: "ROTX",
          value: 0
        },
        {
          type: "field_angle",
          name: "ROTY",
          value: 0
        },
        {
          type: "field_angle",
          name: "ROTZ",
          value: 0
        }
      ],
      args3: [
        {
          type: "field_number",
          name: "SCALE",
          value: 1
        }
      ],
      output: null,
      colour: 160,
      tooltip:
        "Use a TinkerCad or SketchUp 3D model in a zipped folder containing the 3D model. Premium Feature. " +
        "Zip file is accepted.",
      helpUrl: "https://www.c3d.io/help"
    });
    // get models dynamically
    // this.setWarningText("Please add a 3D Module to your asset to use this block")

    if(window['savedModels'].length) {
      console.log("obj", window['savedModels'])
      let drowdown = new Blockly.FieldDropdown(window['savedModels']);      
      this.appendDummyInput("model")
      .appendField("model")
      .appendField(
        drowdown,
        "MODEL"
      )
    } else {
      let drowdown = new Blockly.FieldDropdown([["Sphere", "https://firebasestorage.googleapis.com/v0/b/env3d-blockly-ca.appspot.com/o/files%2Fdefault%2Fsphere.zip?alt=media&token=cf326ea2-4772-496e-96d6-206f17513164"]]);      
      this.appendDummyInput("model")
      .appendField("model")
      .appendField(
        drowdown,
        "MODEL"
      )
    }
  }
};

Blockly.JavaScript["env3d_object_tinker"] = function(block) {
  var x = block.getFieldValue("X") || 0;
  var y = block.getFieldValue("Y") || 0;
  var z = block.getFieldValue("Z") || 0;
  var rotX = block.getFieldValue("ROTX") || 0;
  var rotY = block.getFieldValue("ROTY") || 0;
  var rotZ = block.getFieldValue("ROTZ") || 0;
  var scale = block.getFieldValue("SCALE") || 1;
  var texture = block.getFieldValue("TEXTURE") || "";
  var model = block.getFieldValue("MODEL") || "";
  var mtl = "";
  console.log("model",model);
  if (model.startsWith("http")) {
    if (model.endsWith('zip') || model.split('?')[0].endsWith('zip') ){
      
      // model will work as is, no need to modify
    } else if (model.endsWith("obj")) {
      // assume mtl is stored in the same directory
      mtl =
        "\tmtl: '" +
        model.substr(0, model.lastIndexOf("/") + 1) +
        "obj.mtl" +
        "',\n";
    } else {
      // assume it is a directory
      if (!model.endsWith("/")) model += "/";
      // its a directory, we use default tinkercad obj and mtl names
      mtl = "\tmtl: '" + model + "obj.mtl" + "',\n";
      model += "tinker.obj";
    }
  }
  var obj =
    "{\n" +
    "\tx: " +
    x +
    ", \n" +
    "\ty: " +
    y +
    ", \n" +
    "\tz: " +
    z +
    ", \n" +
    "\trotateX: " +
    rotX +
    ", \n" +
    "\trotateY: " +
    rotY +
    ", \n" +
    "\trotateZ: " +
    rotZ +
    ", \n" +
    "\tscale: " +
    scale +
    ", \n" +
    "\ttexture: null, \n" +
    mtl +
    "\tmodel: '" +
    model +
    "'" +
    "\n}";
  return [obj, Blockly.JavaScript.ORDER_MEMBER];
};

// Object field with default models
Blockly.Blocks["env3d_object"] = {
  init: function() {
    this.jsonInit({
      message0: "3D Object",
      message1: "position %1 %2 %3",
      message2: "rotation %1 %2 %3",
      message3: "scale %1",
      message4: "texture %1",
      message5: "model %1",
      args1: [
        {
          type: "field_number",
          name: "X",
          value: 0
        },
        {
          type: "field_number",
          name: "Y",
          value: 0
        },
        {
          type: "field_number",
          name: "Z",
          value: 0
        }
      ],
      args2: [
        {
          type: "field_angle",
          name: "ROTX",
          value: 0
        },
        {
          type: "field_angle",
          name: "ROTY",
          value: 0
        },
        {
          type: "field_angle",
          name: "ROTZ",
          value: 0
        }
      ],
      args3: [
        {
          type: "field_number",
          name: "SCALE",
          value: 1
        }
      ],
      args4: [
        {
          type: "field_dropdown",
          name: "TEXTURE",
          options: [
            ["tux", "models/tux/tux.png"],
            ["fox", "models/fox/fox.png"]
          ]
        }
      ],
      args5: [
        {
          type: "field_dropdown",
          name: "MODEL",
          options: [
            ["tux", "models/tux/tux.obj"],
            ["fox", "models/fox/fox.obj"]
          ]
        }
      ],
      output: null,
      colour: 160,
      tooltip:
        "Create a 3D object.  You can either assign it to a " +
        "variable or add it directly to the environment",
      helpUrl: "https://www.c3d.io/help"
    });
  }
};

Blockly.JavaScript["env3d_object"] = function(block) {
  var x = block.getFieldValue("X") || 0;
  var y = block.getFieldValue("Y") || 0;
  var z = block.getFieldValue("Z") || 0;
  var rotX = block.getFieldValue("ROTX") || 0;
  var rotY = block.getFieldValue("ROTY") || 0;
  var rotZ = block.getFieldValue("ROTZ") || 0;
  var scale = block.getFieldValue("SCALE") || 1;
  var texture = block.getFieldValue("TEXTURE") || "";
  var model = block.getFieldValue("MODEL") || "";
  var obj =
    "{\n" +
    "\tx: " +
    x +
    ", \n" +
    "\ty: " +
    y +
    ", \n" +
    "\tz: " +
    z +
    ", \n" +
    "\trotateX: " +
    rotX +
    ", \n" +
    "\trotateY: " +
    rotY +
    ", \n" +
    "\trotateZ: " +
    rotZ +
    ", \n" +
    "\tscale: " +
    scale +
    ", \n" +
    "\ttexture: '" +
    texture +
    "', \n" +
    "\tmodel: '" +
    model +
    "'" +
    "\n}";
  return [obj, Blockly.JavaScript.ORDER_MEMBER];
};

class UserModels {
  constructor(base) {
    this.models = [];
    this.baseURL = base;
    this.hash = { default: {} };
  }

  fetchModels() {
    fetch(this.baseURL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        //console.log(json);
        let url = this.baseURL;
        url.slice(-1) != "/"
          ? (url = url.slice(0, url.lastIndexOf("/") + 1))
          : null;
        json.forEach(o => {
          let file = o.fbx ? url + o.fbx : url + o.obj + "&" + url + o.mtl;
          this.models.push([o.name, file]);

          //this.hash['all']['all'].push([o.name, file]);
          let c, t;
          [c, t] =
            o.name.indexOf("/") > -1 ? o.name.split("/") : ["_Misc", "_Misc"];

          let type = t.split("_")[1];
          let category = c.split("_")[1];

          //console.log(c, category, t, type);

          !this.hash[category] && (this.hash[category] = {});
          !this.hash[category][type] && (this.hash[category][type] = []);
          this.hash[category][type].push([o.name, file]);
        });
        console.log("files",this.hash);
      });
  }
  
}

var tinkercadModels = new UserModels(
  "https://api.operatoroverload.com/models/list.php"
);
//setInterval(tinkercadModels.fetchModels.bind(tinkercadModels), 5000);

//var userModels = new UserModels('https://env3d.github.io/env3d-blockly-models/list.js');
var userModels = new UserModels("https://models.c3d.io/list.js");
userModels.fetchModels();

// var addedModel = new UserModels("");
// let myModels = addedModel.getUserModels();

// console.log("files", myModels)

// A function to get all the models available
function generateModels() {
  // Built-in models
  var options = [
    [
      {
        src:
          "https://opengameart.org/sites/default/files/styles/medium/public/tux-400.jpg",
        width: 25,
        height: 25,
        alt: "tux"
      },
      "tux"
    ],
    [
      {
        src:
          "https://opengameart.org/sites/default/files/styles/medium/public/tom-400.jpg",
        width: 25,
        height: 25,
        alt: "fox"
      },
      "fox"
    ],
    [
      {
        src:
          "https://opengameart.org/sites/default/files/styles/medium/public/cubestack4.jpg",
        width: 25,
        height: 25,
        alt: "cube"
      },
      "cube"
    ],
    [
      {
        src:
          "https://opengameart.org/sites/default/files/styles/medium/public/earththumb.png",
        width: 25,
        height: 25,
        alt: "earth"
      },
      "earth"
    ],
    [
      {
        src:
          "https://opengameart.org/sites/default/files/styles/medium/public/igloo.png",
        width: 25,
        height: 25,
        alt: "igloo"
      },
      "igloo"
    ],
    [
      {
        src:
          "https://env3d.c3d.io/models/dog/thumbnail_dog.jpg",
        width: 25,
        height: 25,
        alt: "dog"
      },
      "dog"
    ],
    [
      {
        src:
          "https://env3d.c3d.io/models/rabbit/thumbnail_rabbit.jpg",
        width: 25,
        height: 25,
        alt: "rabbit"
      },
      "rabbit"
    ]
  ];

  //return options.concat(tinkercadModels.models).concat(userModels.models);
  return options;
}

Blockly.Blocks["env3d_object_model"] = {
  init: function() {
    this.jsonInit({
      message0: "3D Object",
      message1: "position: x= %1 y= %2 z= %3",
      message2: "rotation: x= %1 y= %2 z= %3",
      message3: "scale * %1",
      args1: [
        {
          type: "field_number",
          name: "X",
          value: 0
        },
        {
          type: "field_number",
          name: "Y",
          value: 0
        },
        {
          type: "field_number",
          name: "Z",
          value: 0
        }
      ],
      args2: [
        {
          type: "field_angle",
          name: "ROTX",
          value: 0
        },
        {
          type: "field_angle",
          name: "ROTY",
          value: 0
        },
        {
          type: "field_angle",
          name: "ROTZ",
          value: 0
        }
      ],
      args3: [
        {
          type: "field_number",
          name: "SCALE",
          value: 1
        }
      ],
      output: null,
      colour: 160,
      tooltip:
        "Create a 3D object.  You can either assign it to a " +
        "variable or add it directly to the environment",
      helpUrl: "https://www.c3d.io/help"
    });

    // get models dynamically
    var dropdownAll = new Blockly.FieldDropdown(generateModels);
    userModels.hash["default"] = { default: generateModels() };
    
    function categoryCallback(c) {
      try {
        this.getInput("model").removeField("TYPE");
      } catch (err) {
        // Don't really care as sometimes we hide the TYPE field
        // console.log('field TYPE not visible');
      }
      let types = Object.keys(userModels.hash[c]).map(k => [k, k]);
      if (types.length > 1) {
        this.getInput("model").appendField(
          new Blockly.FieldDropdown(types, typeCallback(c).bind(this)),
          "TYPE"
        );
      }
      typeCallback(c).call(this, types[0][1]);
    }

    function typeCallback(c, t) {
      return function(t) {
        this.getInput("model").removeField("MODEL");
        this.getInput("model").appendField(
          new Blockly.FieldDropdown(userModels.hash[c][t]),
          "MODEL"
        );
      };
    }

    // The render function retrieves values from the
    // dynamic fields and set them properly for display
    function render() {
        let c = this.getFieldValue("CATEGORY");
        let t = this.getFieldValue("TYPE");
        let m = this.getFieldValue("MODEL");

        categoryCallback.call(this, c);
        if (t) {
          this.setFieldValue(t, "TYPE");
          typeCallback.call(this, c).call(this, t);
        }
        this.setFieldValue(m, "MODEL");
        //console.log('block created', e, c, t, m);      
    }
    
    // Fill the categories if the various fields already have values
    this.setOnChange(e => {
      console.log(e);
      if(!this.parentBlock_) {
        this.setDisabled(true)
      } else {
        this.setDisabled(false)
      }
      // When we finished loading the block, we make sure that the
      // correct category and type is selected
      // The FinishedLoading event only fires after load from XML
      if (e instanceof Blockly.Events.FinishedLoading) {
        // code has been moved to the MOVE event.  
      }

      if (e instanceof Blockly.Events.Create) {
        this.needRender = true;
      }

      // HACK hide the "type" field after creation.
      // this code should actually be in the CREATE
      // event above, but blockly rendered the object
      // incorrect (see issue #13), so we have to do
      // it here.
      if (e instanceof Blockly.Events.Move) {
        if (this.needRender) {
          this.needRender = false;
          render.call(this);          
        }
      }

    });
    
    this.appendDummyInput("model")
      .appendField("model")
      .appendField(
        new Blockly.FieldDropdown(
          Object.keys(userModels.hash).map(k => [k, k]),
          categoryCallback.bind(this)
        ),
        "CATEGORY"
      )

      .appendField(new Blockly.FieldDropdown([["", ""]]), "TYPE")
      .appendField(dropdownAll, "MODEL");
  }
};

Blockly.JavaScript["env3d_object_model"] = function(block) {
  var x = block.getFieldValue("X") || 0;
  var y = block.getFieldValue("Y") || 0;
  var z = block.getFieldValue("Z") || 0;
  var rotX = block.getFieldValue("ROTX") || 0;
  var rotY = block.getFieldValue("ROTY") || 0;
  var rotZ = block.getFieldValue("ROTZ") || 0;
  var scale = block.getFieldValue("SCALE") || 1;
  var selectedModel = block.getFieldValue("MODEL") || "";

  var texture, model, mtl;

  if (selectedModel.startsWith("http")) {
    texture = null;
    objmtl = selectedModel.split("&");
    model = objmtl[0];
    mtl = objmtl[1];
  } else if (selectedModel == "earth") {
    texture = "textures/earth.png";
    model = "sphere";
  } else if (selectedModel == "rabbit" || selectedModel == "dog") {
    model = "models/" + selectedModel + "/" + selectedModel + ".fbx";
  }
   else {
    texture = "models/" + selectedModel + "/" + selectedModel + ".png";
    model = "models/" + selectedModel + "/" + selectedModel + ".obj";
    mtl = null;
  }

  var obj =
    "{\n" +
    "\tx: " +
    x +
    ", \n" +
    "\ty: " +
    y +
    ", \n" +
    "\tz: " +
    z +
    ", \n" +
    "\trotateX: " +
    rotX +
    ", \n" +
    "\trotateY: " +
    rotY +
    ", \n" +
    "\trotateZ: " +
    rotZ +
    ", \n" +
    "\tscale: " +
    scale +
    ", \n";

  if (texture) {
    obj += "\ttexture: '" + texture + "', \n";
  } else {
    obj += "\ttexture: null, \n";
  }

  if (mtl) {
    obj += "\tmtl: '" + mtl + "', \n";
  }

  obj += "\tmodel: '" + model + "'";
  obj += "\n}";

  return [obj, Blockly.JavaScript.ORDER_MEMBER];
};
