// Blockly config and monkey patching 

// START_HAT seems to have no effect now
Blockly.BlockSvg.START_HAT = true;

Blockly.FieldAngle.OFFSET = 270;

// Below are all the patching code to make things work with env3d

// A hack to cerate a new loop_trap variable for each loop used
window.LOOP_TRAP_COUNT = 0;
// redefine loop generate code to include traps
['controls_repeat_ext','controls_whileUntil','controls_for','controls_forEach'].forEach(function(loop) {
    var _fun = Blockly.JavaScript[loop];
    Blockly.JavaScript[loop] = function(block) {
        var code = _fun(block);
        
        // only activate trap if the LOOP_TRAP_COUNT variable is not negative.
        // we do this so we can turn off loop trap at will
        if (window.LOOP_TRAP_COUNT >= 0) {
            var firstBrace = code.indexOf('{');                 
            code = '\nvar LOOP_TRAP'+(++window.LOOP_TRAP_COUNT)+'=1000;\n'
                 + code.slice(0, firstBrace+1)
                 + '\n  if(--LOOP_TRAP'+(window.LOOP_TRAP_COUNT)+' < 0) {console.warn(\'infinite loop\'); break;}\n'
                 + code.slice(firstBrace+1);
        }
        
        return code;
    };
});

// redefine procedure call so if we are calling inside an env3d event,
// we will have proper access to the frame counter.
// We achieve this by binding to the event loop's scope.
var _proc = Blockly.JavaScript['procedures_callnoreturn'];
Blockly.JavaScript['procedures_callnoreturn'] = function(block) {
    var callCode = _proc(block).trim();

    // splice in .bind(scope) into the function call
    var firstBracket = callCode.indexOf('(');
    var bindCode = callCode.slice(0,firstBracket)+'.bind(this)'+callCode.slice(firstBracket);
    
    var code = 'if (typeof this != "undefined") {'+bindCode+'} else {'+ callCode +'}'
    return code;
}             

// redefine the workspaceToCode so events will be generated last
// code copied from blockly/core/generator.
Blockly.Env3D = {};
Blockly.Env3D.EVENT_BLOCK_TYPES = ['env3d_loop','env3d_loop_timed',
                                   'env3d_event_lookat','env3d_event_lookat_list',
                                   'env3d_event','env3d_event_list'];

Blockly.Generator.prototype.workspaceToCode = function(workspace) {
    if (!workspace) {
        // Backwards compatibility from before there could be multiple workspaces.
        console.warn('No workspace specified in workspaceToCode call.  Guessing.');
        workspace = Blockly.getMainWorkspace();
    }
    var code = [];
    this.init(workspace);
    var blocks = workspace.getTopBlocks();

    // custom sort
    blocks.sort(function(a, b) {
        var event_block_types = Blockly.Env3D.EVENT_BLOCK_TYPES;        
        // a and b are blocks, compare by type, env3d events comes last
        if (event_block_types.indexOf(a.type) > -1 &&
            event_block_types.indexOf(b.type) > -1) {
                return 0;
        }

        if (event_block_types.indexOf(a.type) == -1 &&
            event_block_types.indexOf(b.type) > -1) {
                return -1;
        }

        if (event_block_types.indexOf(a.type) > -1 &&
            event_block_types.indexOf(b.type) == -1) {
                return 1;
        }        
    });
    
    for (var x = 0, block; block = blocks[x]; x++) {
        var line = this.blockToCode(block);
        if (goog.isArray(line)) {
            // Value blocks return tuples of code and operator order.
            // Top-level blocks don't care about operator order.
            line = line[0];
        }
        if (line) {
            if (block.outputConnection && this.scrubNakedValue) {
                // This block is a naked value.  Ask the language's code generator if
                // it wants to append a semicolon, or something.
                line = this.scrubNakedValue(line);
            }
            code.push(line);
        }
    }
    code = code.join('\n');  // Blank line between each section.
    code = this.finish(code);
    // Final scrubbing of whitespace.
    code = code.replace(/^\s+\n/, '');
    code = code.replace(/\n\s+$/, '\n');
    code = code.replace(/[ \t]+\n/g, '\n');
    return code;
};

// replace the default forEach with this one where we use let as the
// loop variable to control scope.
Blockly.JavaScript['controls_forEach'] = function(block) {
    // For each loop.
    var variable0 = Blockly.JavaScript.variableDB_.getName(
        block.getFieldValue('VAR'), Blockly.Variables.NAME_TYPE);
    var argument0 = Blockly.JavaScript.valueToCode(block, 'LIST',
                                                   Blockly.JavaScript.ORDER_ASSIGNMENT) || '[]';
    var branch = Blockly.JavaScript.statementToCode(block, 'DO'); 
    branch = Blockly.JavaScript.addLoopTrap(branch, block.id); 
    var code = '';
    // Cache non-trivial values to variables to prevent repeated look-ups.
    var listVar = argument0; 
    if (!argument0.match(/^\w+$/)) {
        listVar = Blockly.JavaScript.variableDB_.getDistinctName( 
            variable0 + '_list', Blockly.Variables.NAME_TYPE); 
        code += 'var ' + listVar + ' = ' + argument0 + ';\n'; 
    } 
    var indexVar = Blockly.JavaScript.variableDB_.getDistinctName(
        variable0 + '_index', Blockly.Variables.NAME_TYPE); 
    branch = Blockly.JavaScript.INDENT + 'let ' + variable0 + ' = ' +
             listVar + '[' + indexVar + '];\n' + branch; 
    code += 'for (let ' + indexVar + ' in ' + listVar + ') {\n' + branch + '}\n'; 
    return code;
};

// insert await if block is surround by async
Blockly.Block.prototype.insertAwait = function() {
    let surroundParent = this.getSurroundParent();    
    while (surroundParent != null) {
        if (['env3d_loop_sequence', 'env3d_loop_between', 'env3d_loop_at']
            .includes(surroundParent.type)) {
            return 'await ';
        }
        surroundParent = surroundParent.getSurroundParent();  
    }
    return '';
}

// check parent block to see if animation needs to be in chain
Blockly.Block.prototype.isChain = function() {
  let surroundParent = this.getSurroundParent();  
  let isChain = false;  
  while (surroundParent != null) {
      if (['env3d_loop_sequence', 'env3d_loop_between', 'env3d_loop_at']
          .includes(surroundParent.type)) {
          isChain = true;
      }
      surroundParent = surroundParent.getSurroundParent();  
  }
  return isChain;
}
