// Controls the environment - skybox, room textures, etc.
// Utilitiy block allowing a number field with an angle selector

Blockly.Blocks['string_skybox'] = {
    init: function() {
        this.jsonInit({
            "message0": '%1',
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "STRING",
                    "options": [
                        ["default","\'textures/skybox/default\'"],
                        ["cave","\'textures/skybox/cave\'"],
                        ["city","\'textures/skybox/city\'"],
                        ["evening","\'textures/skybox/evening\'"],
                        ["fantasy","\'textures/skybox/fantasy\'"],
                        ["holodeck","\'textures/skybox/holodeck\'"],
                        ["interstellar","\'textures/skybox/interstellar\'"],
                        ["snow","\'textures/skybox/snow\'"],
                        ["sunny","\'textures/skybox/sunny\'"]                        
                    ]
                }
            ],
            "output": null,
	    "colour": 160,
            "tooltip": "Returns skybox choices",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['string_skybox'] = function(block) {
    var skybox = block.getFieldValue('STRING') || '';
    return [skybox, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.Blocks['string_terrain'] = {
    init: function() {
        this.jsonInit({
            "message0": '%1',
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "STRING",
                    "options": [
                        ["none",""],
                        ["ancient","\'textures/floor/AncientFlooring.png\'"],
                        ["clover","\'textures/floor/clover1.png\'"],
                        ["grass1","\'textures/floor/grass1.png\'"],
                        ["grass2","\'textures/floor/grass2.png\'"],
                        ["leaves","\'textures/floor/leaves1.png\'"],
                        ["paving1","\'textures/floor/paving1.png\'"],
                        ["planks","\'textures/floor/planks.png\'"],
                        ["rock1","\'textures/floor/rock1.png\'"],                        
                    ]
                }
            ],
            "output": null,
	    "colour": 160,
            "tooltip": "Returns terrain choices",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['string_terrain'] = function(block) {
    var skybox = block.getFieldValue('STRING') || '';
    return [skybox, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.Blocks['env3d_outdoor_inputs'] = {
    init: function() {
        this.jsonInit({
            "message0": "outdoor",
            "message1": "sky: %1",
            "message2": "terrain: %1",
            "args1": [
                {
                    "type": "input_value",
                    "name": "SKYBOX"
                }                                
            ],
            "args2": [
                {
                    "type": "input_value",
                    "name": "TERRAIN",
                }                                
            ],
            "inputsInline": false,
            "nextStatement": null,
	    "style": "hat_blocks",	    
            "tooltip": "Create an outdor environment",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_outdoor_inputs'] = function(block) {
    //var skybox = block.getFieldValue('SKYBOX');
    //var terrain = block.getFieldValue('TERRAIN');

    var skybox = Blockly.JavaScript.valueToCode(block, 'SKYBOX',
                                                Blockly.JavaScript.ORDER_NONE) || '';
                                                
    var terrain = Blockly.JavaScript.valueToCode(block, 'TERRAIN',
                                                Blockly.JavaScript.ORDER_NONE) || '';
    
    return 'env.setRoom({textureNorth: null, textureSouth: null,'
         + 'textureEast: null, textureWest: null,'
         + 'textureTop: null, textureBottom: null'
         + '});\n'
         + 'env.setSky('+skybox+');\n'
         + 'env.setTerrain('+terrain+');\n'

};

Blockly.Blocks['string_video'] = {
    init: function() {
        this.jsonInit({
            "message0": '" %1 "',
            "args0": [
              {
                "type": "field_input",
                "name": "STRING",
                "text": ''
                // "options": [
                //     ["tunnel","\'textures/pano.webm\'"],
                //     ["shark","\'textures/shark.mp4\'"]
                // ]
              }
            ],
            "output": null,
            "colour": 160,
            "tooltip": "Returns skybox choices",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['string_video'] = function(block) {
    var video = block.getFieldValue('STRING') || '';
    return [video, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.Blocks['env3d_background_video_inputs'] = {
    init: function() {
        this.jsonInit({
            "message0": "360 background %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "VIDEO",
                }                                
            ],
            "nextStatement": null,
	    "style": "hat_blocks",
            "tooltip": "Upload a 360 image to Asset by drag and drop, then paste the link here",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_background_video_inputs'] = function(block) {
    var video = Blockly.JavaScript.valueToCode(block, 'VIDEO',
                                                Blockly.JavaScript.ORDER_NONE) || '';
    
    return 'env.setRoom({textureNorth: null, textureSouth: null,'
         + 'textureEast: null, textureWest: null,'
         + 'textureTop: null, textureBottom: null'
         + '});\n'
         + 'env.setBackgroundVideo('+video+');\n';
};

Blockly.Blocks['string_wall'] = {
    init: function() {
        this.jsonInit({
            "message0": '%1',
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "STRING",
                    "options": [
                        ["none", "null"],
                        ["earth","'textures/earth.png'"],
                        ["black","'textures/black.png'"],
                        ["red","'textures/red.png'"],    
                        ["fence0", "'textures/fence0.png'"],
                        ["fence1", "'textures/fence1.png'"],
                        ["floor", "'textures/floor.png'"],
                        ["granite", "'textures/granite.png'"],
                        ["marble", "'textures/marble.png'"],
                        ["mud", "'textures/mud.png'"],
                        ["stop", "'textures/stop.png'"]    
                    ],
                }],
            "output": null,
            "colour": 160,
            "tooltip": "Returns skybox choices",
            "helpUrl": "https://www.c3d.io/help"
        })
    }
};

Blockly.JavaScript['string_wall'] = function(block) {
    var wall = block.getFieldValue('STRING') || '';
    return [wall, Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.Blocks['env3d_room_inputs'] = {
    init: function() {
        this.jsonInit({
            "message0": "Room",
            "message1": "top: %1",
            "message2": "bottom: %1",
            "message3": "north %1",
            "message4": "east %1",
            "message5": "south %1",
            "message6": "west %1",
            "message7": "width %1 height %2 depth %3",
            "args1": [
                {
                    "type": "input_value",
                    "name": "TOP"
                }                                
            ],
            "args2": [
                {
                    "type": "input_value",
                    "name": "BOTTOM"
                }                                
            ],
            "args3": [
                {
                    "type": "input_value",
                    "name": "NORTH"
                }                                
            ],
            "args4": [
                {
                    "type": "input_value",
                    "name": "EAST"
                }                                
            ],
            "args5": [
                {
                    "type": "input_value",
                    "name": "SOUTH"
                }                                
            ],
            "args6": [
                {
                    "type": "input_value",
                    "name": "WEST"
                }                                
            ],
            "args7": [
                {
                    "type": "input_value",
                    "name": "WIDTH"
                },
                {
                    "type": "input_value",
                    "name": "HEIGHT"
                },                    
                {
                    "type": "input_value",
                    "name": "DEPTH"
                }                     
            ],
            "nextStatement": null,
	    "style": "hat_blocks",
            "tooltip": "Change up the look of the starter room",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_room_inputs'] = function(block) {
    var top = Blockly.JavaScript.valueToCode(block, 'TOP',
                                             Blockly.JavaScript.ORDER_NONE) || "''";
    var bottom = Blockly.JavaScript.valueToCode(block, 'BOTTOM',
                                             Blockly.JavaScript.ORDER_NONE) || "''";
    var north = Blockly.JavaScript.valueToCode(block, 'NORTH',
                                             Blockly.JavaScript.ORDER_NONE) || "''";
    var east = Blockly.JavaScript.valueToCode(block, 'EAST',
                                             Blockly.JavaScript.ORDER_NONE) || "''";
    var south = Blockly.JavaScript.valueToCode(block, 'SOUTH',
                                             Blockly.JavaScript.ORDER_NONE) || "''";
    var west = Blockly.JavaScript.valueToCode(block, 'WEST',
                                             Blockly.JavaScript.ORDER_NONE) || "''";

    var width = Blockly.JavaScript.valueToCode(block, 'WIDTH',
                                             Blockly.JavaScript.ORDER_NONE) || 10;
    var height = Blockly.JavaScript.valueToCode(block, 'HEIGHT',
                                             Blockly.JavaScript.ORDER_NONE) || 10;
    var depth = Blockly.JavaScript.valueToCode(block, 'DEPTH',
                                             Blockly.JavaScript.ORDER_NONE) || 10;
    
    
    return 'env.setRoom({textureNorth: '+north+', textureSouth: '+south+',\n'
         + ' textureEast: '+east+', textureWest: '+west+',\n'
         + ' textureTop: '+top+', textureBottom: '+bottom+',\n'
         + ' width: '+width+', depth: '+depth+', height:'+height
         + '});\n';
};

// -------- fields instead of inputs --------

Blockly.Blocks['env3d_outdoor'] = {
    init: function() {
        this.jsonInit({
            "message0": "outdoor environment",
            "message1": "sky: %1",
            "message2": "terrain: %1",
            "args1": [
                {
                    "type": "field_dropdown",
                    "name": "SKYBOX",
                    "options": [
                        ["default","textures/skybox/default"],
                        ["cave","textures/skybox/cave"],
                        ["city","textures/skybox/city"],
                        ["evening","textures/skybox/evening"],
                        ["fantasy","textures/skybox/fantasy"],
                        ["holodeck","textures/skybox/holodeck"],
                        ["interstellar","textures/skybox/interstellar"],
                        ["snow","textures/skybox/snow"],
                        ["sunny","textures/skybox/sunny"]                        
                    ]
                }                                
            ],
            "args2": [
                {
                    "type": "field_dropdown",
                    "name": "TERRAIN",
                    "options": [
                        ["none",""],
                        ["ancient"," /floor/AncientFlooring.png"],
                        ["clover","textures/floor/clover1.png"],
                        ["grass1","textures/floor/grass1.png"],
                        ["grass2","textures/floor/grass2.png"],
                        ["leaves","textures/floor/leaves1.png"],
                        ["paving1","textures/floor/paving1.png"],
                        ["paving1","textures/floor/paving1.png"],
                        ["planks","textures/floor/planks.png"],
                        ["rock1","textures/floor/rock1.png"],                        
                    ]
                }                                
            ],
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Create an outdor environment",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_outdoor'] = function(block) {
    var skybox = block.getFieldValue('SKYBOX');
    var terrain = block.getFieldValue('TERRAIN');
    return 'env.setRoom({textureNorth: null, textureSouth: null,'
         + 'textureEast: null, textureWest: null,'
         + 'textureTop: null, textureBottom: null'
         + '});\n'
         + 'env.setSky("'+skybox+'");\n'
         + 'env.setTerrain("'+terrain+'");\n';
};

Blockly.Blocks['env3d_background_video'] = {
    init: function() {
        this.jsonInit({
            "message0": "360 background video",
            "message1": "video: %1",
            "args1": [
                {
                    "type": "field_dropdown",
                    "name": "VIDEO",
                    "options": [
                        ["tunnel","textures/pano.webm"],
                        ["shark","textures/shark.mp4"]
                    ]
                }                                
            ],
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Use 360 video as background",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_background_video'] = function(block) {
    var video = block.getFieldValue('VIDEO');
    return 'env.setRoom({textureNorth: null, textureSouth: null,'
         + 'textureEast: null, textureWest: null,'
         + 'textureTop: null, textureBottom: null'
         + '});\n'
         + 'env.setBackgroundVideo("'+video+'");\n';
};


var textures = [
    ["none", ""],
    ["earth","textures/earth.png"],
    ["black","textures/black.png"],
    ["red","textures/red.png"],    
    ["fence0", "textures/fence0.png"],
    ["fence1", "textures/fence1.png"],
    ["floor", "textures/floor.png"],
    ["granite", "textures/granite.png"],
    ["marble", "textures/marble.png"],
    ["mud", "textures/mud.png"],
    ["stop", "textures/stop.png"]    
];

Blockly.Blocks['env3d_room'] = {
    init: function() {
        this.jsonInit({
            "message0": "Room",
            "message1": "top: %1",
            "message2": "bottom: %1",
            "message3": "north %1",
            "message4": "east %1",
            "message5": "south %1",
            "message6": "west %1",
            "message7": "width %1 height %2 depth %3",
            "args1": [
                {
                    "type": "field_dropdown",
                    "name": "TOP",
                    "options": textures
                }                                
            ],
            "args2": [
                {
                    "type": "field_dropdown",
                    "name": "BOTTOM",
                    "options": textures
                }                                
            ],
            "args3": [
                {
                    "type": "field_dropdown",
                    "name": "NORTH",
                    "options": textures
                }                                
            ],
            "args4": [
                {
                    "type": "field_dropdown",
                    "name": "EAST",
                    "options": textures
                }                                
            ],
            "args5": [
                {
                    "type": "field_dropdown",
                    "name": "SOUTH",
                    "options": textures
                }                                
            ],
            "args6": [
                {
                    "type": "field_dropdown",
                    "name": "WEST",
                    "options": textures
                }                                
            ],
            "args7": [
                {
                    "type": "field_number",
                    "name": "WIDTH",
                    "value": 10
                },
                {
                    "type": "field_number",
                    "name": "HEIGHT",
                    "value": 10
                },                    
                {
                    "type": "field_number",
                    "name": "DEPTH",
                    "value": 10
                }                     
            ],
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Change up the look of the starter room",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_room'] = function(block) {
    var top = block.getFieldValue('TOP');
    var bottom = block.getFieldValue('BOTTOM');
    var north = block.getFieldValue('NORTH');
    var east = block.getFieldValue('EAST');
    var south = block.getFieldValue('SOUTH');
    var west = block.getFieldValue('WEST');
    var width = block.getFieldValue('WIDTH') || 10;
    var height = block.getFieldValue('HEIGHT') || 10;
    var depth = block.getFieldValue('DEPTH') || 10;

    
    return 'env.setRoom({textureNorth: "'+north+'", textureSouth: "'+south+'",\n'
         + ' textureEast: "'+east+'", textureWest: "'+west+'",\n'
         + ' textureTop: "'+top+'", textureBottom: "'+bottom+'",\n'
         + ' width: '+width+', depth: '+depth+', height:'+height
         + '});\n';
};

['env3d_outdoor', 'env3d_outdoor_inputs',
 'env3d_background_video', 'env3d_background_video_inputs',
 'env3d_room', 'env3d_room_inputs'].forEach(function(type) {
    
    Blockly.Blocks[type].onchange = function() {
        if (this.isInFlyout) return;

        if (this.getParent() != null) {
            this.setDisabled(true);
            this.setWarningText('World block must be appear first');
            return;
        }

        if (this.getNextBlock() == null) {
            this.setDisabled(true);
            this.setWarningText('World block must be attached to another command');
            return;
        }
        
        this.setDisabled(false);
        this.setWarningText(null);                
    };
    
});
