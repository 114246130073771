// A tinkercad object with url field for model
Blockly.Blocks['env3d_sound_play'] = {
    init: function() {
        this.jsonInit({
            "message0": "Play sound %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "AUDIO"
                }                                
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Plays a sound file, needs to provide an url",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_sound_play'] = function(block) {
    var soundFile = Blockly.JavaScript.valueToCode(block, 'AUDIO',
                                                   Blockly.JavaScript.ORDER_NONE) || null;    
    let code = [
        '// play sound '+soundFile,
        block.insertAwait() + `env.soundPlay(${soundFile});\n`
    ];
    return code.join('\n');
};


Blockly.Blocks['env3d_sound_loop'] = {
    init: function() {
        this.jsonInit({
            "message0": "Loop sound %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "AUDIO"
                }                                
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Plays a sound file, needs to provide an url",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_sound_loop'] = function(block) {
    var soundFile = Blockly.JavaScript.valueToCode(block, 'AUDIO',
                                                   Blockly.JavaScript.ORDER_NONE) || null;    
    let code = [
        '// play sound '+soundFile,
        block.insertAwait() + `env.soundLoop(${soundFile});`
    ];
    return code.join('\n');
};


Blockly.Blocks['env3d_sound_stop'] = {
    init: function() {
        this.jsonInit({
            "message0": "Stop sound %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "AUDIO"
                }                                
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Plays a sound file, needs to provide an url",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_sound_stop'] = function(block) {
    var soundFile = Blockly.JavaScript.valueToCode(block, 'AUDIO',
                                                   Blockly.JavaScript.ORDER_NONE) || null;    
    let code = [
        '// play sound '+soundFile,
        block.insertAwait() + `env.soundStop(${soundFile});`
    ];
    return code.join('\n');
};

