
Blockly.Blocks['env3d_loop_sequence'] = {
    init: function() {
        this.jsonInit({
            "message0": "do in sequence",
            "message1": "%1",
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Execute statements in sequence",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop_sequence'] = function(block) {
    let code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    // let numOfTween = 0;

    // // if there is not a block inside this block, return nothing
    // if(code.length <= 0) return " ";
    // let codeArray = code.split("\n") // splite by enter character
    //   .filter(aCode => {
    //     // remove the array elem that does not contain anything
    //     return aCode.length > 0 
    //   })
    //   .map(aCode => {
    //     // trim space between the code
    //     return aCode.trim(" ")
    //   })
    //   .map((aCode, index) => {
    //     // add variable 
    //     const animationFunc = ["env.moveObject", "env.turnObject", "env.turnToFace", "env.waitFor"]
    //     if(animationFunc.find(aFunc => {
    //       // console.log("match", aCode, aCode.includes(aFunc))
    //       return aCode.includes(aFunc)
    //     })) {
    //       return `let tween${numOfTween++} = ${aCode}`
    //     } else {
    //       return aCode
    //     }
    //   })

    // let chain = "";
    // // codeArray.forEach((aCode, index) => {
    // //   if(index < codeArray.length-1) {
    // //     chain += `tween${index}.chain(tween${index+1})\n`
    // //   }
    // // });
    // if(numOfTween > 1) {
    //   for (let i = 0; i < numOfTween - 1; i++) {
    //     chain += `tween${i}.chain(tween${i+1})\n` 
    //   }
    // }


    // code = `${codeArray.join("\n")}\n\n${chain}\ntween0.start()`
    code = [
      `(async () => {`,
      `  if (typeof this.async !== 'undefined') this.async++;`,
      code,
      `})()`,
      `.then(() => { if (typeof this.async !== 'undefined') this.async--; });\n`
  ].join('\n');

    let wrapper = code;
    let surroundParent = block.getSurroundParent();
    if (surroundParent &&
        ['env3d_loop', 'env3d_loop_timed', 'env3d_event_lookat','env3d_event_lookat_list', 'env3d_event']
            .includes(surroundParent.type)) {
                wrapper = [`if (this.frame == 0) {`,
                           code.split('\n').map(l=>Blockly.JavaScript.INDENT+l).join('\n'),
                           `}\n`].join('\n');
    }
  //   if (surroundParent &&
  //     ['controls_repeat_ext', 'controls_whileUntil', 'controls_for']
  //         .includes(surroundParent.type)) {
  //             wrapper = [
  //               code,
  //               `tween${codeArray.length-1}.onComplete(() => {`,
  //               `${Blockly.JavaScript.INDENT}if(count < 5) {`,
  //               `${Blockly.JavaScript.INDENT}tween0.yoyo()}})`,
  //             ].join('\n')
  //             // console.log("Surround parent", surroundParent)
  // }

    return wrapper;
};

Blockly.Blocks['env3d_wait'] = {
    init: function() {
        this.jsonInit({
            "message0": 'Wait for %1 seconds',
            "args0": [
                {
                    "type": "input_value",
                    "name": "DURATION"
                }                
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Wait for x number of seconds inside a sequence",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_wait'] = function(block) {
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                             Blockly.JavaScript.ORDER_NONE) || 0;
    
    return block.insertAwait() + `env.waitFor(${duration}*1000);\n`;
};


// Blocks for performing actions on objects, such as moving and turning
Blockly.Blocks['env3d_move'] = {
    init: function() {
        this.jsonInit({
            "message0": 'Move %1 %2 for %3 unit in %4 seconds',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT"
                },
                {
                    "type": "field_dropdown",
                    "name": "DIR",
                    "options": [
                        ["forward","forward"],
                        ["backward","backward"],
                        ["left","left"],
                        ["right","right"],
                        ["up","up"],
                        ["down","down"]                        
                    ]                
                },
                {
                    "type": "input_value",
                    "name": "VALUE"
                },
                {
                    "type": "input_value",
                    "name": "DURATION"
                }                
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Move object in a certain direction",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_move'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT',
                                             Blockly.JavaScript.ORDER_NONE) || {};
    var dir = block.getFieldValue('DIR') || "up";
    var val = Blockly.JavaScript.valueToCode(block, 'VALUE',
                                             Blockly.JavaScript.ORDER_NONE) || 0;
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                             Blockly.JavaScript.ORDER_NONE) || 0;
    
    // return block.insertAwait() + `env.moveObject(${obj}, ${val}, '${dir}', ${duration}*1000);\n`;
    
    return block.insertAwait() + `env.moveObject(${obj}, ${val}, '${dir}', ${duration}*1000, ${block.isChain()});\n`;

};

Blockly.Blocks['env3d_turn'] = {
    init: function() {
        this.jsonInit({
            "message0": 'Turn %1 %2 %3 degree in %4 seconds',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT"
                },
                {
                    "type": "field_dropdown",
                    "name": "DIR",
                    "options": [
                        ["left","left"],
                        ["right","right"],
                        ["up","up"],
                        ["down","down"]                        
                    ]                
                },
                {
                    "type": "input_value",
                    "name": "VALUE"
                },
                {
                    "type": "input_value",
                    "name": "DURATION"
                }
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "turn the first object to face the second object",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_turn'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT',
                                             Blockly.JavaScript.ORDER_NONE) || {};
    var dir = block.getFieldValue('DIR') || "up";
    var val = Blockly.JavaScript.valueToCode(block, 'VALUE',
                                             Blockly.JavaScript.ORDER_NONE) || 0;
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                             Blockly.JavaScript.ORDER_NONE) || 0;
    
    return block.insertAwait() + `env.turnObject(${obj}, ${val}, '${dir}', ${duration}*1000, ${block.isChain()});\n`;
};


Blockly.Blocks['env3d_turn_to_face'] = {
    init: function() {
        this.jsonInit({
            "message0": 'Turn %1 to face %2 in %3 seconds',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT1"
                },
                {
                    "type": "input_value",
                    "name": "OBJECT2"
                },
                {
                    "type": "input_value",
                    "name": "DURATION"
                }
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "turn the first object to face the second object",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_turn_to_face'] = function(block) {
    var obj1 = Blockly.JavaScript.valueToCode(block, 'OBJECT1',
                                             Blockly.JavaScript.ORDER_NONE) || {};
    var obj2 = Blockly.JavaScript.valueToCode(block, 'OBJECT2',
                                             Blockly.JavaScript.ORDER_NONE) || {};
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                             Blockly.JavaScript.ORDER_NONE) || 0;

    return block.insertAwait() + `env.turnToFace(${obj1}, ${obj2}, ${duration*1000}, ${block.isChain()});\n`;
};

Blockly.Blocks['env3d_close_to'] = {
    init: function() {
        this.jsonInit({
            "message0": '%1 is touching %2',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT1"
                },
                {
                    "type": "input_value",
                    "name": "OBJECT2"
                }
            ],
            "inputsInline": true,
            "output": null,
            "colour": 160,
            "tooltip": "returns true if object 1 is close to object 2",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_close_to'] = function(block) {
    var obj1 = Blockly.JavaScript.valueToCode(block, 'OBJECT1',
                                             Blockly.JavaScript.ORDER_NONE) || {};
    var obj2 = Blockly.JavaScript.valueToCode(block, 'OBJECT2',
                                             Blockly.JavaScript.ORDER_NONE) || {};

    var functionName = Blockly.JavaScript.provideFunction_( 
        'env3dCloseTo',
        ['function ' + Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ +
         '(a, b) {',
         '  if (!a || !b) return false;',
         '  var xdiff = Math.pow(a.x-b.x,2);',
         '  var ydiff = Math.pow(a.y-b.y,2);',
         '  var zdiff = Math.pow(a.z-b.z,2);',
         '  var dist = Math.pow(a.scale+b.scale,2);',
         '  return ((xdiff+ydiff+zdiff) <= dist);',
         '}']); 
    var code = functionName+'('+obj1+','+obj2+')'; 
    
    //setRotateY(Math.toDegrees(Math.atan2(gameObj.getX()-this.getX(),gameObj.getZ()-this.getZ()))); 
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

// Attach this function to all onchange handlers to restrict timing blocks to event loops
var onchange = function() {
    if (!this.workspace.isDragging || this.workspace.isDragging()) {
        return;  // Don't change state at the start of a drag.
    }
    
    var legal = false;
    // Is the block nested in a procedure?
    var block = this;        
    do {
        let parent = block.getSurroundParent();
                
        if (this.LEGAL_TYPES.indexOf(block.type) != -1) {
            legal = true;
            break;
        }
        
        // traverse upwards
        block = parent
        
    } while (block);
    
    if (legal) {
        if (!this.isInFlyout) {
            this.setDisabled(false);
            this.setWarningText(null);
        }
    } else {
        if(this.type === 'env3d_text_prompt_ext'){
            console.log("legalTypes",this.type)
            this.setWarningText('NEVER put this block inside a loop');
        } else {
            if (!this.isInFlyout && !this.getInheritedDisabled()) {
                this.setDisabled(false);
                this.setWarningText('Animation is best inside "do in sequence" or timing blocks');
            }
        }
        
    }
}
            
// timing blocks are only allowed inside loops and events
var LEGAL_TYPES = ['env3d_loop_sequence','env3d_loop_between','env3d_loop_at','procedures_defnoreturn'];
var ACTION_TYPES = ['env3d_move', 'env3d_turn',
                    'env3d_turn_to_face','env3d_wait',
                    'env3d_display_string', 'env3d_camera_move'];
ACTION_TYPES.forEach(function(type){
    Blockly.Blocks[type].onchange = onchange;
    Blockly.Blocks[type].LEGAL_TYPES = LEGAL_TYPES;    
});
