Blockly.Blocks['env3d_water'] = {
    init: function() {
        this.jsonInit({
            "message0": 'Water',
            "message1": 'position %1 %2 %3',
            "message2": 'rotation %1 %2 %3',            
            "message3": 'scale %1',
            "args1": [
                {
                    "type": "field_number",
                    "name": "X",
                    "value": 0
                },
                {
                    "type": "field_number",
                    "name": "Y",
                    "value": 0
                },
                {
                    "type": "field_number",
                    "name": "Z",
                    "value": 0
                }                                
            ],
            "args2": [
                {
                    "type": "field_angle",
                    "name": "ROTX",
                    "value": 0
                },
                {
                    "type": "field_angle",
                    "name": "ROTY",
                    "value": 0
                },
                {
                    "type": "field_angle",
                    "name": "ROTZ",
                    "value": 0
                }                                
            ],
            "args3": [
                {
                    "type": "field_number",
                    "name": "SCALE",
                    "value": 1
                }
            ],
            "output": null,
            "colour": 160,
            "tooltip": "Create water object.  You can either assign it to a "
                     + "variable or add it directly to the environment",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_water'] = function(block) {
    var x = block.getFieldValue('X') || 0;
    var y = block.getFieldValue('Y') || 0;
    var z = block.getFieldValue('Z') || 0;    
    var rotX = block.getFieldValue('ROTX') || 0;
    var rotY = block.getFieldValue('ROTY') || 0;
    var rotZ = block.getFieldValue('ROTZ') || 0;    
    var scale = block.getFieldValue("SCALE") || 1;
    var obj = 'new env3d.advanced.EnvWater({\n' 
            + '\tx: ' + x + ', \n'
            + '\ty: ' + y + ', \n'
            + '\tz: ' + z + ', \n'
            + '\trotateX: ' + rotX + ', \n'
            + '\trotateY: ' + rotY + ', \n'
            + '\trotateZ: ' + rotZ + ', \n'
            + '\tscale: ' + scale + ', \n'
            + '\n})';
    return [obj, Blockly.JavaScript.ORDER_MEMBER];
};
