// Animation loop - basically events

Blockly.Blocks['env3d_loop'] = {
    init: function() {
        this.jsonInit({
            "message0": "30 times per second",
            "message1": "do: %1",
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],
	    "style": "hat_blocks",
            "tooltip": "Executes a block of code 30 times per second, "
                     + "useful for creating animation",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop'] = function(block) {
    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    return [`env.eventLoop( function() {`,
            code,
            `});\n`].join('\n');
};

// Timed loop
Blockly.Blocks['env3d_loop_timed'] = {
    init: function() {
        this.jsonInit({
            "message0": "Repeat every %1 seconds",
            "message1": "do: %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "SECONDS"
                }                                                
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],
	    "style": "hat_blocks",	    
            "tooltip": "A sequence over and over",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_loop_timed'] = function(block) {
    var seconds = Blockly.JavaScript.valueToCode(block, 'SECONDS',
        Blockly.JavaScript.ORDER_NONE) || 0;
    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');    
    return [`env.eventLoop( function() {`,
            code,
            `}, ${seconds});\n`].join('\n');  
};

function eventLookAtCode(obj, code) {
    return [`env.eventLookAt(${obj}, function(){`,
            code,
            `});\n`].join('\n');
}

// Event when you look at something
Blockly.Blocks['env3d_event_lookat'] = {
    init: function() {
        this.jsonInit({
            "message0": "When you look at %1",
            "message1": "do: %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT"
                }
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],
	    "style": "hat_blocks",	    
            "tooltip": "Executes a block of code if you look at something",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_event_lookat'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT',
        Blockly.JavaScript.ORDER_NONE) || 0;

    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    return eventLookAtCode(obj, code);
};

// Event when you look at something
Blockly.Blocks['env3d_event_lookat_list'] = {
    init: function() {
        this.jsonInit({
            "message0": "When you look at item %1 in list %2",
            "message1": "do: %1",
            "args0": [
                {
                    "type": "field_variable",
                    "name": "VAR",
                    "value": "i"
                },
                {
                    "type": "input_value",
                    "name": "LIST"
                }
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ],            
	    "style": "hat_blocks",	    
            "tooltip": "Executes a block of code if you look at something",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_event_lookat_list'] = function(block) {
    var obj = block.getField('VAR').text_;
    var list = Blockly.JavaScript.valueToCode(block, 'LIST',
        Blockly.JavaScript.ORDER_NONE) || 0;
    // list.forEach()
    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    return list + '.forEach(function('+ obj +', __idx) { \n'
         + eventLookAtCode(obj, code).split('\n').map(l => `  ${l}`).join('\n')
         + `\n`
         + '});\n'
};

// Event when a generic condition is satisified
Blockly.Blocks['env3d_event'] = { 
    init: function() {
        this.jsonInit({
            "message0": "When condition %1 is true",
            "message1": "do: %1",
            "args0": [
                {
                    "type": "input_value",
                    "name": "CONDITION"
                }
            ],
            "args1": [
                {
                    "type": "input_statement",
                    "name": "BLOCK"
                }                                
            ], 
            "inputsInline": true,
	    "style": "hat_blocks",	    
            "tooltip": "Executes a block of code if a condition is true",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_event'] = function(block) { 
    var cond = Blockly.JavaScript.valueToCode(block, 'CONDITION',
                                              Blockly.JavaScript.ORDER_NONE) || false;

    var code = Blockly.JavaScript.statementToCode(block, 'BLOCK');
    return [`env.eventCondition(() => ${cond}, function() { `,
           code,
           `});\n`].join('\n');
    
}; 

// Additional list remove block
Blockly.Blocks['lists_remove_item'] = {
    init: function() {
        this.jsonInit({
            "message0": "from list %1 remove item %2",
            "args0": [
                {
                    "type": "input_value",
                    "name": "LIST"
                },                            
                {
                    "type": "input_value",
                    "name": "ITEM"
                }                                
            ],
            "previousStatement": null,
            "nextStatement": null,
            "inputsInline": true,
            "colour": Blockly.Blocks.lists.HUE,
            "tooltip": "Remove an item from a list",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['lists_remove_item'] = function(block) {
    // Find an item in the list.
    var item = Blockly.JavaScript.valueToCode(block, 'ITEM',
                                              Blockly.JavaScript.ORDER_NONE) || '\'\''; 
    var list = Blockly.JavaScript.valueToCode(block, 'LIST',
                                              Blockly.JavaScript.ORDER_MEMBER) || '[]';
    var functionName = Blockly.JavaScript.provideFunction_( 
        'listRemoveItem',
        ['function ' + Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ +
         '(list, item) {',
         '  if (list.indexOf(item) > -1) {',
         '    list.splice(list.indexOf(item),1);',
         '  }',
         '}']);
    var code = functionName+'('+list+','+item+');\n'; 
    return code;
}; 
