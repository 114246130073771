// Setup firebase
var firebase = require("firebase/app");
require("firebase/auth");
require("firebase/database");
require("firebase/storage");

let shortid = require('shortid');

// var config = {
//     apiKey: "AIzaSyAK_qV8fh-uuKOY03zFOUCih4rDaEo95pI",
//     authDomain: "env3d-blockly.firebaseapp.com",
//     databaseURL: "https://env3d-blockly.firebaseio.com",
//     projectId: "env3d-blockly",
//     storageBucket: "env3d-blockly.appspot.com",
//     messagingSenderId: "650401913525"
// }; 

var config = {
  apiKey: "AIzaSyDgLIe7Fi0eWJlDz3mzcSh6rvyefQaLgMM",
  authDomain: "env3d-blockly-ca.firebaseapp.com",
  databaseURL: "https://env3d-blockly-ca.firebaseio.com",
  projectId: "env3d-blockly-ca",
  storageBucket: "env3d-blockly-ca.appspot.com",
  messagingSenderId: "846534956161",
  appId: "1:846534956161:web:cf160c1097e7419c566980"
};
window["myLocation"] = "https://env3d-blockly-ca.firebaseio.com";

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

// make firebase available in the global namespace so index can access it
// @todo: fix this by moving all JS into src
window['firebase'] = firebase;
window['database'] = firebase.database();
// make shortid available
// @todo: fix this by moving all JS into src
//window['shortid'] = require('shortid');

// window["cloudURL"] = window.location.host.includes("myc3d.test") || window.location.host.includes("localhost")  ? "http://localhost:5000" : "https://us-central1-env3d-blockly-ca.cloudfunctions.net/api";

window["cloudURL"] = "https://api.c3d.io"

// window["cloudURL"] = "http://api.myc3d.test"

// window["cloudURL"] = "https://us-central1-env3d-blockly-ca.cloudfunctions.net/test"

// window["cloudURL"] = window.location.host.includes("myc3d.test") || window.location.host.includes("localhost")  ? "http://localhost:5000" : "https://api.c3d.io";

// Some utility functions
window['getQueryVariable'] = function (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0;i < vars.length;i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

if(window.location.host.includes("myc3d.test")) {
  window["classroomURL"] = "http://teacher.myc3d.test";
} else if (getQueryVariable("origin") === "C3D") {
  window["classroomURL"] = "https://classroom.c3d.io"
} else {
  window["classroomURL"] = `https://${getQueryVariable("origin")}.c3d.io`
}

// wrap code around an async
window['asyncFunc'] = function (code, duration) {
  return new Promise(function (resolve, reject) {
    code();
    var interval = setInterval(() => {
      code();
    }, 32);
    setTimeout(() => {
      clearInterval(interval);
      resolve();
    }, duration);
  });
}

window['copyToClipboard'] = function (text) {
  if (!navigator.clipboard) {
    alert(`Please copy the link ${text} and paste it in c3d.io`);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {
    alert('Link Copied. Use it by pasting the link in a relevant field on a coding block.');
  }).catch(e => {
    console.log('error copying to clipboard', e);
  });
}

window['newWorld'] = (uid) => {
  let name = prompt("Name of the new world: ");

  if (name !== null) {
    let storageId = shortid.generate();

    let updates = {};
    updates[`/users/${uid}/${storageId}`] = name;
    updates[`/temp/${storageId}/owner`] = uid;
    // console.log(updates);

    database.ref().update(updates, (err) => {
      if (err) {
        console.log(err);
      } else {
        window.location.href = window.location.origin + `/?id=${storageId}`;
      }
    });
  }

}

window['getCookie'] = (name) => {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

//fetch models of users
window['getUserModels'] = async (uid) => {
  return new Promise((resolve, reject) => {
    // store the user id as sub-key
    let userKey = "files/" + uid + "/";
    let addedModel = [];
    database.ref(userKey).on("value", snapshot => {
      // clear exist models before populating them again
      console.log('files savedModels-----',snapshot.exists(), snapshot.val())
      addedModel = [];
      if (snapshot.exists()) {
        let files = snapshot.val();
        Object.keys(files).forEach(file => {
          if (files[file].name.includes(".zip")) {
            addedModel.push([
              files[file].name.split(".zip")[0],
              files[file].url
            ]);
          }
        });
        // update saved models on change in database
        window["savedModels"] = addedModel
        resolve(addedModel);
      } else {
        if(window.savedModels) delete window['savedModels']
        console.log('files savedModels',window.savedModels)
        console.log("files: none");
        window["savedModels"] = []
        console.log('files savedModels',window.savedModels)
        resolve([])
      }
    });
  })
}

window['TWEEN'] = require('@tweenjs/tween.js');
