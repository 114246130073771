
// Returns the object directly under the center of screen (i.e. eye)
Blockly.Blocks['env3d_eye'] = {
    init: function() {
        this.jsonInit({
            "message0": 'object under eye as trigger',
            "output": null,
            "colour": 160,
            "tooltip": "Retrieves the object under cursor once",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_eye'] = function(block) {
    return ['env.getPickOnce()', Blockly.JavaScript.ORDER_MEMBER];
};

// Returns the object directly under the center of screen (i.e. eye)
Blockly.Blocks['env3d_eye_stateless'] = {
    init: function() {
        this.jsonInit({
            "message0": 'object under eye',
            "output": null,
            "colour": 160,
            "tooltip": "Retrieves the object under the cursor",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_eye_stateless'] = function(block) {
    return ['env.getPick()', Blockly.JavaScript.ORDER_MEMBER];
};
