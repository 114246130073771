
// Set the camera position
Blockly.Blocks['env3d_camera'] = {
    init: function() {
        this.jsonInit({
            "inputsInline": true,
            "message0": "camera",
            "message1": "position: %1 %2 %3",
            "args1": [
                {
                    "type": "field_number",
                    "name": "X",
                    "value": 5
                },
                {
                    "type": "field_number",
                    "name": "Y",
                    "value": 5
                },
                {
                    "type": "field_number",
                    "name": "Z",
                    "value": 25
                }                                
            ],
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Set the camera location",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_camera'] = function(block) {

    var x = block.getFieldValue('X') || 5;
    var y = block.getFieldValue('Y') || 5;
    var z = block.getFieldValue('Z') || 15;

    return 'env.setCameraXYZ('
         + x + ', '
         + y + ', '
         + z 
         + '); \n';
};

// Allow camera position to use inputs 
Blockly.Blocks['env3d_camera_inputs'] = {
    init: function() {
        this.jsonInit({
            "inputsInline": true,
            "message0": "camera",
            "message1": "xyz: %1 %2 %3 pitch: %4 yaw: %5",
            "args1": [
                {
                    "type": "input_value",
                    "name": "X"
                },
                {
                    "type": "input_value",
                    "name": "Y"
                },
                {
                    "type": "input_value",
                    "name": "Z"
                },
                {
                    "type": "input_value",
                    "name": "PITCH"
                },
                {
                    "type": "input_value",
                    "name": "YAW"
                }
                
            ],
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Set the camera location, allows blocks as inputs",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_camera_inputs'] = function(block) {
    var x = Blockly.JavaScript.valueToCode(block, 'X', Blockly.JavaScript.ORDER_ATOMIC) || 5;
    var y = Blockly.JavaScript.valueToCode(block, 'Y', Blockly.JavaScript.ORDER_ATOMIC) || 5;
    var z = Blockly.JavaScript.valueToCode(block, 'Z', Blockly.JavaScript.ORDER_ATOMIC) || 15;    

    var pitch = Blockly.JavaScript.valueToCode(block, 'PITCH', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    var yaw = Blockly.JavaScript.valueToCode(block, 'YAW', Blockly.JavaScript.ORDER_ATOMIC) || 0;    
    
    return 'env.setCameraXYZ('
         + x + ', '
         + y + ', '
         + z 
         + '); \n'
         + 'env.setCameraPitch('+pitch+');\n'
         + 'env.setCameraYaw('+yaw+');\n';
};

// Allow camera position to use inputs 
Blockly.Blocks['env3d_camera_move'] = {
    init: function() {
        this.jsonInit({
            "inputsInline": true,
            "message0": "move camera",
            "message1": "to: %1 %2 %3 pitch %4 yaw %5 in %6 seconds",
            "args1": [
                {
                    "type": "input_value",
                    "name": "X"
                },
                {
                    "type": "input_value",
                    "name": "Y"
                },
                {
                    "type": "input_value",
                    "name": "Z"
                },
                {
                  "type": "input_value",
                  "name": "PITCH"
                },
                {
                  "type": "input_value",
                  "name": "YAW"
                },
                {
                  "type": "input_value",
                  "name": "SECONDS"
              },
            ],
            "previousStatement": null,
            "nextStatement": null,            
            "colour": 160,
            "tooltip": "Set the camera location, allows blocks as inputs",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_camera_move'] = function(block) {
    
    var x = Blockly.JavaScript.valueToCode(block, 'X', Blockly.JavaScript.ORDER_ATOMIC) || 5;
    var y = Blockly.JavaScript.valueToCode(block, 'Y', Blockly.JavaScript.ORDER_ATOMIC) || 5;
    var z = Blockly.JavaScript.valueToCode(block, 'Z', Blockly.JavaScript.ORDER_ATOMIC) || 15;   
    
    var pitch = Blockly.JavaScript.valueToCode(block, 'PITCH', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    var yaw = Blockly.JavaScript.valueToCode(block, 'YAW', Blockly.JavaScript.ORDER_ATOMIC) || 0;    

    var duration = Blockly.JavaScript.valueToCode(block, 'SECONDS', Blockly.JavaScript.ORDER_ATOMIC) || 0.01;

    
    return block.insertAwait() + 
      `env.moveCamera(${x}, ${y}, ${z}, ${pitch}, ${yaw}, ${duration}, ${block.isChain()});\n`
};

// Returns an object that proxy's the camera's xyz property
Blockly.Blocks['env3d_camera_proxy'] = {
    init: function() {
        this.jsonInit({
            "message0": 'camera\'s location',
            "output": null,
            "colour": 160,
            "tooltip": "Retrieves the camera's location",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_camera_proxy'] = function(block) {    
    return [
        `{x: env.camera.position.x, y: env.camera.position.y, z: env.camera.position.z}`
        , Blockly.JavaScript.ORDER_MEMBER
    ];
};
