
// HUD related blocks

Blockly.Blocks['env3d_display_string'] = {
    init: function() {
        this.jsonInit({
            "message0": 'show message %1 for %2 seconds',
            "args0": [
                {
                    "type": "input_value",
                    "name": "MESSAGE"
                },
                {
                    "type": "input_value",
                    "name": "DURATION"
                }                
            ],
            "nextStatement": true,
            "previousStatement": true,
            "colour": 160,
            "tooltip": "Shows a message on screen",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_display_string'] = function(block) {
    var message = Blockly.JavaScript.valueToCode(block, 'MESSAGE',
                                                 Blockly.JavaScript.ORDER_NONE) || null;
    var duration = Blockly.JavaScript.valueToCode(block, 'DURATION',
                                                 Blockly.JavaScript.ORDER_NONE) || null;

    return block.insertAwait() + `env.showMessage(${message}, ${duration}*1000);\n`;
};

