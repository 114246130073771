
// Allow mutation of 3d object's fields
Blockly.Blocks['env3d_fields'] = {
    init: function() {
        this.jsonInit({
            "message0": 'set object %1\'s property %2 to %3',
            "args0": [
                {
                    "type": "field_variable",
                    "name": "OBJECT",
                    "variable": "tux"
                },
                {
                    "type": "field_dropdown",
                    "name": "PROP",
                    "options": [
                        ["x", ".x"],
                        ["y", ".y"],
                        ["z", ".z"],
                        ["rotateX", ".rotateX"],
                        ["rotateY", ".rotateY"],
                        ["rotateZ", ".rotateZ"],
                        ["scale", ".scale"],                        
                        ["texture", ".texture"],
                        ["model", ".model"]                        
                    ]
                },
                {
                    "type": "input_value",
                    "name": "VAL",
                    "text": "value"                    
                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Sets an object's property to another value",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_fields'] = function(block) {
    var obj = block.getFieldValue('OBJECT') || '{}';
    var prop = block.getFieldValue('PROP') || '';
    var val = Blockly.JavaScript.valueToCode(block, 'VAL',
                                   Blockly.JavaScript.ORDER_NONE) || ''; 
    
    //var val = block.getFieldValue('VAL') || '';
    return obj+prop+' = '+val+';\n';
};

// Allow mutation of 3d object's fields
Blockly.Blocks['env3d_fields_input'] = {
    init: function() {
        this.jsonInit({
            "message0": 'change object %1\'s property %2 by %3',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT",
                },
                {
                    "type": "field_dropdown",
                    "name": "PROP",
                    "options": [
                        ["x", ".x"],
                        ["y", ".y"],
                        ["z", ".z"],
                        ["rotateX", ".rotateX"],
                        ["rotateY", ".rotateY"],
                        ["rotateZ", ".rotateZ"],
                        ["scale", ".scale"],
                        ["texture", ".texture"],
                        ["model", ".model"]                        
                    ]
                },
                {
                    "type": "field_number",
                    "name": "VAL",
                    "text": "value"                    
                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Increase or decrease an object's property",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_fields_input'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_ATOMIC) || {};
    var prop = block.getFieldValue('PROP') || '';
    var val = block.getFieldValue('VAL') || 0;
    return obj+" && ("+obj+prop+' += '+val+');\n';
};

// Allow mutation of 3d object's fields
Blockly.Blocks['env3d_fields_input_by_input'] = {
    init: function() {
        this.jsonInit({
            "message0": 'change object %1\'s property %2 by %3',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT",
                },
                {
                    "type": "field_dropdown",
                    "name": "PROP",
                    "options": [
                        ["x", ".x"],
                        ["y", ".y"],
                        ["z", ".z"],
                        ["rotateX", ".rotateX"],
                        ["rotateY", ".rotateY"],
                        ["rotateZ", ".rotateZ"],
                        ["scale", ".scale"]                        
                    ]
                },
                {
                    "type": "input_value",
                    "name": "VAL",
                }
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Increase or decrease an object's property",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_fields_input_by_input'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_ATOMIC) || {};
    var prop = block.getFieldValue('PROP') || '';
    var val = Blockly.JavaScript.valueToCode(block, 'VAL', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    return obj+" && ("+obj+prop+' += '+val+');\n';
};

// Setting of 3d object's fields
Blockly.Blocks['env3d_fields_set_by_input'] = {
    init: function() {
        this.jsonInit({
            "message0": 'set object %1\'s property %2 to %3',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT",
                },
                {
                    "type": "field_dropdown",
                    "name": "PROP",
                    "options": [
                        ["x", ".x"],
                        ["y", ".y"],
                        ["z", ".z"],
                        ["rotateX", ".rotateX"],
                        ["rotateY", ".rotateY"],
                        ["rotateZ", ".rotateZ"],
                        ["scale", ".scale"],
                        ["texture", ".texture"],
                        ["model", ".model"]                        
                    ]
                },
                {
                    "type": "input_value",
                    "name": "VAL",
                }
            ],
            "inputsInline": true,
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Increase or decrease an object's property",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_fields_set_by_input'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_ATOMIC) || {};
    var prop = block.getFieldValue('PROP') || '';
    var val = Blockly.JavaScript.valueToCode(block, 'VAL', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    return obj+" && ("+obj+prop+' = '+val+');\n';
};

// Retrieve property from env3d object
Blockly.Blocks['env3d_property'] = {
    init: function() {
        this.jsonInit({
            "message0": 'retrieves %1\'s property %2',
            "args0": [
                {
                    "type": "field_variable",
                    "name": "OBJECT",
                    "variable": "tux"
                },
                {
                    "type": "field_dropdown",
                    "name": "PROP",
                    "options": [
                        ["x", ".x"],
                        ["y", ".y"],
                        ["z", ".z"],
                        ["rotateX", ".rotateX"],
                        ["rotateY", ".rotateY"],
                        ["rotateZ", ".rotateZ"],
                        ["scale", ".scale"],                        
                        ["texture", ".texture"],
                        ["model", ".model"]                        
                    ]
                }
            ],
            "output": null,
            "colour": 160,
            "tooltip": "Retrieves a particular property from a 3D object",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_property'] = function(block) {
    var obj = block.getFieldValue('OBJECT') || '{}';
    var prop = block.getFieldValue('PROP') || '';
    return [obj+prop, Blockly.JavaScript.ORDER_MEMBER];
};

// Retrieve property from env3d object
Blockly.Blocks['env3d_property_input'] = {
    init: function() {
        this.jsonInit({
            "message0": 'retrieves %1\'s property %2',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT",
                },
                {
                    "type": "field_dropdown",
                    "name": "PROP",
                    "options": [
                        ["x", ".x"],
                        ["y", ".y"],
                        ["z", ".z"],
                        ["rotateX", ".rotateX"],
                        ["rotateY", ".rotateY"],
                        ["rotateZ", ".rotateZ"],
                        ["scale", ".scale"],                        
                        ["texture", ".texture"],
                        ["model", ".model"]                        
                    ]
                }
            ],
            "output": null,
            "colour": 160,
            "tooltip": "Retrieves a particular property from a 3D object",
            "helpUrl": "https://www.c3d.io/help"
        });
    }
};

Blockly.JavaScript['env3d_property_input'] = function(block) {
    var obj = Blockly.JavaScript.valueToCode(block, 'OBJECT',
                                             Blockly.JavaScript.ORDER_NONE) || {};    
    var prop = block.getFieldValue('PROP') || '';
    return [obj+prop, Blockly.JavaScript.ORDER_MEMBER];
};
