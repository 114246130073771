
Blockly.Blocks['env3d_addObject'] = {
    init: function() {
        this.jsonInit({
            "message0": 'add object %1',
            "args0": [
                {
                    "type": "input_value",
                    "name": "OBJECT"
                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "colour": 160,
            "tooltip": "Add a 3D Object to the environment",
            "helpUrl": "https://www.c3d.io/help"
        });

	// When change happens (applies to parent changes too).  Make sure that
	// this block is on top as it's easily hidden.  We also disable it
	// if it has no children.
	this.setOnChange(() => {	    
	    this.getChildren().length == 0 ? this.setDisabled(true) : this.setDisabled(false);	    
        if(this.getChildren().length == 0){
            this.setWarningText("This stray block should be deleted unless attached to an object block")
        } else {
            this.setWarningText(null)
        }
	    this.bringToFront();
	});
    }
};

Blockly.JavaScript['env3d_addObject'] = function(block) {
    var arg = Blockly.JavaScript.valueToCode(block, 'OBJECT',
                                   Blockly.JavaScript.ORDER_NONE) || '{x:0}'; 

    //var arg = block.getFieldValue('OBJECT') || '{}';
    return 'env.addObject('+arg+');\n';
};

