// All the requires 
require('./blockly_env3d/monkey_patch.js');
require('./blockly_env3d/env3d_add_object.js');
require('./blockly_env3d/env3d_camera_control.js');
require('./blockly_env3d/env3d_environment.js');
require('./blockly_env3d/env3d_events.js');
require('./blockly_env3d/env3d_events_timing.js');
require('./blockly_env3d/env3d_hud.js');
require('./blockly_env3d/env3d_object.js');
require('./blockly_env3d/env3d_water.js');
require('./blockly_env3d/env3d_custom_prompt'); // custom dialog for prompt
require('./blockly_env3d/env3d_object_actions.js');
require('./blockly_env3d/env3d_object_fields.js');
require('./blockly_env3d/env3d_picking.js');
require('./blockly_env3d/env3d_sound.js');

require('./blockly_env3d/env3d_custom_dialog');


